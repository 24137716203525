/* ACTIVE SELLERS */

.activeSellers {
    padding: 0;
    margin: 0;
    background: #666;
}

.activeSellers > .MuiButtonBase-root.MuiListItem-root {
    padding: 12px;
    border-bottom: 1px solid #444;
    color: #fff;
    align-items: flex-start;
    height: 80px;
}

.activeSellers > .MuiButtonBase-root.MuiListItem-root.active {
    background: #444444;
}

.activeSellers .userAvatar {
    font-size: 50px;
    color: #000;
    margin-right: 5px;
}

.activeSellers .asContent h6 {
    color: #b2b2b2;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    margin-left: auto;
}

.activeSellers .asContent .asTitle {
    color: #fff;
    font-weight: 500;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 14px;
}

.activeSellers .asContent .asSubject {
    color: #c5c5c5;
    font-size: 12px;
    margin-bottom: 0;
    padding-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-top: 2px;
}

.activeSellers .asContent .MuiBadge-root {
    margin-right: 10px;
    margin-top: 5px;
}

/* ACTIVE SELLERS END */

/* ACTIVE SUBJECTS */

.activeSubjects {
    padding: 0;
    margin: 0;
}

.activeSubjects > .MuiButtonBase-root.MuiListItem-root {
    border-bottom: 1px solid #444;
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
    align-items: flex-start;
    height: 80px;
}

.activeSubjects > .MuiButtonBase-root.MuiListItem-root.active {
    background: #f2f2f2;
}

.activeSubjects .subjectContent h4 {
    font-weight: 700;
    color: #666;
    font-size: 15px;
    line-height: 1.4;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
}

.activeSubjects .subjectContent h6 {
    color: #b2b2b2;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}

.activeSubjects .asContent .asSubject {
    color: #b1aeae;
    font-size: 12px;
    margin-bottom: 0;
    padding-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 2px;
}

.activeSubjects .asContent .MuiBadge-root {
    margin-right: 10px;
    margin-top: 12px;
    margin-left: 20px;
    color: #fff;
}

/* ACTIVE SUBJECTS END */

/* CONVERSATIONS */

.conversations {
    background: #e5e5e5;
}

.conversations .conversationsCnt {
    padding: 15px;
}

.conversations .dateSent {
    margin: 15px 0;
}

.conversations .dateSent hr {
    background-color: #d3d1d1;
    width: 33%;
}

.conversations .dateSent span {
    font-size: 14px;
    display: inline-block;
    color: #999;
    background: #f2f2f2;
    padding: 5px 20px;
    border-radius: 20px;
    margin: 0 5px;
}

.conversations .messageReceived {
    display: inline-block;
    margin: 5px;
    padding: 10px;
    background: #fff;
    color: #666;
    border-radius: 4px;
    border-bottom: 2px solid #b2b2b2;
    max-width: 280px;
    font-size: 14px;
    position: relative;
    word-break: break-word;
    text-align: left;
}

.conversations .messageReceived p,
.conversations .messageSent p {
    margin: 0;
}

.conversations .messageRow {
    width: 100%;
}

.conversations .messageReceived .timeStamp {
    float: right;
    font-size: 12px;
    font-weight: 600;
    color: #bbbbbb;
}

.conversations .messageSent .timeStamp {
    float: right;
    font-size: 12px;
    font-weight: 600;
    color: #b5f5c7;
}

.conversations .messageSent {
    display: inline-block;
    margin: 5px;
    padding: 10px;
    font-size: 14px;
    background: var(--primColor);
    color: #fff;
    border-radius: 4px;
    border-bottom: 2px solid var(--primColor);
    max-width: 280px;
    position: relative;
    word-break: break-word;
    text-align: left;
}

.conversations .deleteMsg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    min-width: 0;
    color: #868686;
    background: #ccc;
}

.conversations .deleteMsg .MuiButton-label .material-icons {
    font-size: 20px;
}

.conversations .messageInput {
    display: flex;
    width: 100%;
    background: #fff;
    padding: 10px 5px;
}

.conversations .messageInput .customInput {
    margin: 0;
    width: 75%;
}

.conversations .messageInput .msgActBtn {
    width: 25%;
}

.conversations .messageInput .msgActBtn .primButton button {
    border-radius: 50px;
    border-bottom: none;
}

/* CONVERSATIONS END*/

.messages {
    margin-bottom: 45px;
}

.messages .messageTop {
    margin: 20px 0;
}

.messages .messageTop h2 {
    font-weight: 700;
    color: #fff;
    font-size: 20px;
}

.messages .messageTop .MuiButton-root .MuiButton-label {
    color: #b2b2b2;
    font-size: 14px;
    text-transform: initial;
    font-weight: 700;
}

.messages .messageTop .MuiButton-root.active .MuiButton-label {
    color: var(--primColor);
}

.messages .messageCnt {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
}

.messages .messageCnt .messageHead {
    padding: 15px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.messages .messageCnt .messageHead > * {
    margin-bottom: 0;
    text-align: left;
}

.messages .messageCnt .messageHead h4 {
    width: 25%;
    font-weight: 700;
    color: #666;
    font-size: 18px;
}

.messages .messageCnt .messageHead h5 {
    width: 25%;
    font-weight: 700;
    color: #666;
    font-size: 18px;
}

.messages .messageCnt .messageHead h5 span {
    color: var(--primColor);
}

.messages .messageCnt .messageHead h6 {
    width: 50%;
    text-align: right;
    color: #b2b2b2;
    font-size: 15px;
}

.messages .messageCnt .activeSellers,
.messages .messageCnt .activeSubjects {
    width: 25%;
    height: 500px;
    overflow: auto;
}

.messages .messageCnt .conversations {
    width: 50%;
}

.messages .messageCnt .conversations .conversationsCnt {
    height: 425px;
    overflow: auto;
}

.nomessage {
    width: 100%;
    padding-top: 200px;
    text-align: center;
    height: 500px;
    background: #666;
    font-size: 30px;
}

.uploadInput {
    display: none;
}

.uploadedImage {
    background: #666;
    width: 100%;
    height: 80px;
    padding: 4px 30px;
    position: relative;
}

.uploadedImage img {
    height: 70px;
    width: 70px;
}

.closeImage {
    position: absolute;
    top: 0;
    right: 0;
}

.imageConversation {
    width: 100%;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.imageConversation img {
    width: 250px;
    height: 250px;
}

.toggleSellers {
    display: none !important;
}

.toggleSellers .MuiButton-label {
    text-transform: initial;
    color: var(--primColor);
    font-weight: 600;
}

.toggleSellers .material-icons {
    padding-right: 5px;
}

.messageCnt .msgActBtn label {
    margin-bottom: 0;
}

.messageCnt .msgActBtn .attachBtn {
    min-width: max-content;
}

.msgDrawer .msgHead {
    background: #dedede;
    padding: 15px;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 16px;
}

.msgDrawer .MuiPaper-root.MuiDrawer-paper {
    max-width: 450px;
    width: 100%;
}

.msgDrawer .backSellerBtn {
    margin: 5px 0;
}

.msgDrawer .backSellerBtn .MuiButton-label {
    text-transform: initial;
    color: #909090;
}

.msgDrawer .backSellerBtn .material-icons {
    padding-right: 10px;
}

.noSelection {
    padding: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noSelection span {
    font-size: 50px;
    color: #cacaca;
}

.noSelection h4 {
    font-size: 18px;
    color: #979797;
    line-height: 20px;
    margin-top: 5px;
    font-weight: 700;
}

.noSelection h6 {
    font-size: 15px;
    line-height: 20px;
    color: #979797;
    margin-bottom: 0px;
}

.noSelection.seller {
    padding-bottom: 90px;
}

@media (max-width: 1024px) {
    .messages .messageCnt .activeSellers,
    .messages .messageCnt .activeSubjects {
        display: none;
    }
    .messages .messageCnt .conversations {
        width: 100%;
    }
    .messages .messageCnt .messageHead h4 {
        display: none;
    }
    .messages .messageCnt .messageHead h5 {
        width: max-content;
    }
    .messages .messageCnt .messageHead h6 {
        width: max-content;
    }
    .toggleSellers {
        display: inline-flex !important;
    }
    .conversations .messageInput .msgActBtn {
        width: max-content;
    }
    .conversations .messageInput .customInput {
        width: inherit;
    }
}

@media (max-width: 600px) {
    .messages .messageCnt .messageHead button {
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 5px;
        padding-left: 0;
    }
    .messages .messageCnt .messageHead h5 {
        font-size: 15px;
    }
    .conversations .messageSent {
        max-width: 100%;
    }
    .messages .messageCnt .conversations .conversationsCnt {
        height: 345px;
    }
    .msgDrawer .MuiPaper-root.MuiDrawer-paper {
        max-width: 300px;
    }
}
