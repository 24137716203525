.image-wrapper {
    background: url('../../assets/images/homeBack.webp');
    width: 100%;
    height: 100%;
    min-height: 699px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;
    display: flex;
    color: #3f3c53;
}
.categoriesSection {
    position: absolute;
    bottom: 15%;
    left: 0;
    right: 0;
}
.new-image {
    background: url('../../assets/images/teams.png');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.categoriesSection img {
    max-height: 120px;
    height: 100%;
}
.computers h2 {
    font-weight: 600;
}
.auctions .searchLt,
.auctions .searchNav {
    display: none;
}
.auctions .np-pro-box {
    margin-bottom: 0px;
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.divider::after {
    content: '';
    width: 100%;
    height: 5px;
    background: currentColor;
    display: block;
    margin-top: -5px;
}
.shape {
    margin-top: 10%;
}
.content {
    margin: 70px auto;
}
.wholesale {
    font-weight: 600;
    font-size: 23px;
}
.lead {
    margin-top: 12px;
    font-weight: 500;
}
.joinShip {
    font-weight: 600;
    font-size: 20px;
}
.readmore {
    background-color: #ff0000 !important;
    padding: 11px 45px !important;
    font-size: 20px !important;
    border-radius: 30px !important;
    margin-top: 96px !important;
    border: 1px solid #ff0000 !important;
}
.pb-15 {
    padding-bottom: 5rem;
}
.mb-13 {
    margin-bottom: 4rem;
}
.text-lg-start {
    text-align: left !important;
}
.py-15 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.py-16 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.m2-n21 {
    margin-top: 12.5rem;
}
.mt-md-n23 {
    margin-top: -17.5rem !important;
}
.pb-md-17 {
    padding-bottom: 7rem;
}
.py-md-18 {
    padding-top: 8rem;
    padding-bottom: 8rem;
}
.py-md-17 {
    padding-top: 7rem;
}
.pb-md-19 {
    padding-bottom: 9rem;
}
.four {
    border-radius: 50%;
    /* width: 170px;
    height: 170px;
    margin: 0px 50px; */
    display: flex;
    margin: 0px 35px;
}
.four .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.mb-md-17 {
    margin-bottom: 7rem;
}
.mt-n19 {
    margin-top: -9rem;
}
.mt-n17 {
    margin-top: -7rem;
}
.mb-14 {
    margin-bottom: 4.5rem;
}
.four p {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
.four .material-icons {
    font-size: 30px !important;
    margin-bottom: 15px;
    color: #ef4d49;
}
.computers .inner-container {
    max-width: 1200px;
    margin: 0 auto;
}
.comp_parts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
}
.comp_parts .part img {
    height: 100px;
    margin-bottom: 10px;
}
.world {
    font-size: 12px;
}
.part .material-icons {
    color: #ef4d49;
    margin-bottom: 15px;
}
.bg-pink {
    background-color: #f7f3f5;
}
.part h3 {
    font-size: 18px !important;
    font-weight: 700;
    color: var(--primColor);
}
.part p {
    margin-top: 15px;
    font-weight: 500;
}
.allCats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
}
@media screen and (max-width: 992px) {
    .comp_parts {
        grid-template-columns: 1fr 1fr;
    }
    .categoriesSection {
        bottom: 3%;
    }
    .categoriesSection img {
        max-height: 80px;
    }
}
@media screen and (max-width: 900px) {
    .four {
        margin: 0px 20px;
    }
}
@media screen and (max-width: 768px) {
    .categoriesSection {
        bottom: 3%;
    }
}
@media screen and (max-width: 725px) {
    .four {
        margin: 0px 5px;
    }
}
@media screen and (max-width: 600px) {
    .categoriesSection img {
        max-height: 50px;
    }
    .allCats .card-body {
        padding: 10px !important;
    }
    .readmore {
        font-size: 16px !important;
    }
    .wholesale,
    .joinShip {
        font-size: 18px;
    }
    .lead {
        font-size: 17px;
    }
    .content {
        margin: 41px auto;
    }
    .comp_parts {
        grid-template-columns: 1fr;
    }
    .image-wrapper {
        min-height: 460px;
    }
}
@media screen and (max-width: 430px) {
    .readmore {
        margin-top: 36px !important;
    }
    .wholesale,
    .joinShip {
        font-size: 15px;
    }
    .lead {
        font-size: 13px;
    }
}
