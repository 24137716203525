.invoiceWrapper {
    background: #fff;
    padding-bottom: 60px;
    padding-top: 20px;
    text-align: left;
}

.downloadInvoice {
    max-width: 900px;
    width: 100%;
    background: #fff;
    margin: 0 auto;
    /* padding: 30px; */
}
#invoice {
    height: auto !important;
}
.invoiceWrapper .invoice {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0px 30px 30px 30px;
    min-height: 75vh;
}

.invoiceWrapper .invoice .invoicePaid {
    color: var(--primColor);
}

.invoiceWrapper .invoice .invoiceUnpaid {
    color: #ff5503;
}

.invoiceWrapper .invoice .invoiceInfo {
    color: #666;
    position: relative;
}
.invoice .table {
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.invoiceWrapper .invoice .invoiceInfo h2 {
    font-size: 24px;
    margin: 0;
    color: inherit;
    font-weight: 600;
    text-transform: uppercase;
}

.invoiceWrapper .invoice .invoiceInfo label {
    font-size: 14px;
    margin-bottom: 5px;
    color: inherit;
}

.invoiceWrapper .invoice .invoiceInfo h5 {
    font-size: 16px;
    color: inherit;
    margin: 0;
}

.invoiceWrapper .invoice .invoiceInfo .invoiceLogo {
    width: 100%;
    max-width: 150px;
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
}

.invoiceWrapper .invoice .invoiceInfo h3 {
    font-size: 16px;
    font-weight: 600;
    color: inherit;
    margin: 0;
}

.invoiceWrapper .invoice .invoiceInfo address {
    font-size: 16px;
    margin: 0;
    color: inherit;
    word-break: break-word;
}

.invoiceWrapper .invoice .salesRepInfo {
    float: right;
    text-align: left;
}

.invoiceWrapper .invoice .salesRepInfo h6 {
    font-size: 12px;
    margin-bottom: 8px;
    color: inherit;
}

.invoiceWrapper .invoice .salesRepInfo h5 {
    font-size: 13px;
    font-weight: 600;
    color: inherit;
    margin: 0;
    margin-top: 20px;
}

.invoiceWrapper .invoice .invoiceDue label {
    color: #666;
}

.invoiceWrapper .invoice .invoiceDue h4 {
    font-size: 18px;
}

.invoiceWrapper .invoice .invoiceDue .totalDue {
    display: flex;
    background: #f2f2f2;
    border: 1px solid #e5e5e5;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    min-width: 270px;
    margin-bottom: 10px;
}

.invoiceWrapper .invoice .invoiceDue {
    margin-top: 20px;
}

.invoiceWrapper .invoice .invoiceDue .totalDue label {
    margin: 0;
    font-size: 16px;
    color: #666;
    font-weight: 600;
}

.invoiceWrapper .invoice .invoiceDue .totalDue h4 {
    margin: 0;
    font-size: 18px;
    color: var(--primColor);
    font-weight: 600;
}
.tooling {
    color: #000;
    background-color: #e1e1e1;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer;
}
td > img {
    width: 100px;
    height: 75px;
    border-radius: 4px;
}
@media (max-width: 768px) {
    .invoiceWrapper .invoice {
        padding: 0px 20px 20px 20px;
    }
    .invoiceWrapper {
        padding-bottom: 0px;
        padding-top: 0px;
        padding: 20px;
    }
}
@media (max-width: 600px) {
    .invoiceWrapper .invoice {
        padding: 0px 15px 15px 15px;
    }
    .invoiceWrapper .invoice .invoiceInfo .invoiceLogo {
        top: -128px;
        max-width: 108px;
    }
    .buyerAddress {
        margin-bottom: 10px;
    }
    .invoiceWrapper .invoice .salesRepInfo h5 {
        margin-top: 0px;
    }
}
