.custom-modal {
    display: flex;
    align-items: center;
}

.custom-modal .modal-dialog {
    width: 90%;
    max-width: 900px;
}

.custom-modal .modal-header {
    background: #fff;
    border-radius: 10px 10px 0 0;
    /* height: 60px; */
    justify-content: center;
    align-items: center;
}

.custom-modal .modal-header .modal-logo {
    width: 120px;
}

.custom-modal .modal-title {
    line-height: 1.5;
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    padding: 8px 12px;
    font-size: 16px;
    margin-left: 15px;
    background: var(--primColor);
    border-radius: 4px 30px 30px 4px;
    width: auto;
}

.custom-modal .modal-header button.close {
    position: absolute;
    top: 15px;
    right: 15px;
}

.custom-modal .card {
    width: 100%;
    height: 166px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #eaeaea;
    max-width: 800px;
    margin: 10px auto;
}

.custom-modal .card-text,
.custom-modal .card-title {
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    color: #111;
}

.custom-modal .modal-body {
    max-height: calc(75vh - 60px);
    overflow: auto;
}

.custom-modal .modal-content {
    border: none;
}

.custom-modal .modal-dialog.modal-lg {
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
}

.custom-footer {
    display: flex;
    justify-content: center;
}

textarea {
    color: #666;
    border-color: var(--primColor);
}

@media screen and (max-width: 767px) {
    .custom-modal .modal-title {
        font-size: 12px;
    }

    .custom-modal .modal-header .modal-logo {
        width: 100px;
    }

    .custom-modal .modal-dialog.modal-lg {
        width: 90%;
        max-width: 700px;
    }

    .custom-modal .modal-body {
        max-height: calc(60vh - 60px);
        overflow: auto;
    }
}

@media screen and (max-width: 500px) {
    .custom-modal .modal-dialog.modal-lg {
        width: 90%;
        max-width: 400px;
        margin-top: 100px;
    }
}
