.favoriteCheck {
    width: 40px;
    height: 45px;
    /* background: rgb(255, 70, 70, 10%); */
    border-top-left-radius: 3px;
    position: relative;
}

.favoriteCheck input {
    display: none;
}

.favoriteCheck label {
    color: var(--secColor);
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    margin: 0;
    margin-top: 5px;
    width: 40px;
    height: 40px;
}

.favoriteCheck label .material-icons {
    width: 24px;
    font-size: 35px;
}

.favoriteCheck label::selection {
    color: none;
    background: transparent;
}

.favoriteCheck label::moz-selection {
    color: none;
    background: transparent;
}

.favoriteCheck input:checked + label {
    color: var(--primColor);
}
