.grid-img-wrap {
    position: relative;
}
.checkout {
    min-height: 100vh;
}
.media-body .cob-top .address-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.media-body .cob-top h3 {
    font-size: 16px;
    font-weight: 600;
    color: inherit;
    margin: 0;
    text-align: left;
}
.media-body .cob-top address {
    font-size: 16px;
    margin: 0;
    text-align: left;
}
.media-body .cob-top .material-icons {
    color: var(--primColor);
}

.addressForm .addrActBtn .primButton,
.addressForm .addrActBtn .secButton {
    margin: 0;
    min-width: 150px;
}
.addressForm .addrActBtn .primButton {
    margin-left: 15px;
}
.co-box {
    padding: 15px;
    background: #f5f5f5;
    border-radius: 10px;
    margin: 10px 0px;
    margin-top: 0;
}
.checkout .media-left {
    max-width: 200px;
    margin-right: 20px;
    width: 100%;
}
.zipClass .MuiOutlinedInput-input {
    padding: 18.5px 12px !important;
}
.grid-img-wrap img {
    width: 100%;
}
.cob-top {
    margin-bottom: 10px;
    position: relative;
    /* padding-right: 80px; */
}
.co-label {
    font-size: 16px;
    text-align: left;
    color: #5d646c;
    margin-bottom: 2px;
}
.co-text {
    font-size: 18px;
    text-align: left;
    color: #000;
}
.ptp-wrapper .od-head {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 1rem;
    line-height: 28px;
}
.ptp-wrapper .chkcard {
    margin-bottom: 15px;
    display: block;
    background: #fff;
    border: 2px dashed #c9c9c9;
    border-radius: 10px;
    padding: 25px 20px;
}
.ptp-wrapper .chkcard span {
    display: flex;
    margin: 4px 0;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}
.ptp-wrapper .chkcard span p {
    margin: 0;
    font-size: 16px;
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
}
.ptp-wrapper .chkcard span p:nth-child(2) {
    width: 105px;
    text-align: left;
}
/* .ptp-wrapper .chkcard span p + p {
    min-width: 40%;
} */
.od-box {
    border-radius: 10px;
    background: #fff;
    padding: 25px 20px;
    border: 2px dashed #c9c9c9;
}
.TBCText .secButton button {
    border: none;
    background-color: transparent;
    height: fit-content;
    width: fit-content;
}
.TBCText .secButton button:hover {
    color: var(--primColor);
}
.TBCText {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.borderlessTextField {
    max-width: 410px;
    width: 100%;
}
.borderlessTextField .btlLabel {
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    display: block;
}
.borderlessTextField .btlInput {
    font-size: 16px;
    color: #000;
    padding: 8.5px 15px;
    border: 1px solid #cfcece;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.cardSelect .cardmain .radio-item {
    display: inline-block;
    position: relative;
}

.cardSelect label.cardmain .vcRad {
    position: relative;
}

.cardSelect label.cardmain .vcRad:before {
    content: ' ';
    display: inline-block;
    position: relative;
    top: 0px;
    margin: auto;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid var(--primColor);
    background-color: transparent;
}
.cardSelect .cardmain input[type='radio'] {
    opacity: 0;
}
.cardSelect label.cardmain .radio-item input[type='radio']:checked + .vcRad:after {
    border-radius: 11px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 1px;
    right: 5px;
    content: ' ';
    display: block;
    background: var(--primColor);
}

/* radiobox ened */

.cardSelect .cardmain {
    background: #fffaee;
    border: 1px solid var(--primColor);
    border-radius: 10px;
    padding: 12px 20px;
    line-height: 25px;
    margin: 15px auto;
    width: 100%;
}

.cardSelect .flx p {
    margin: auto 0;
}

.cardSelect .flx:nth-child(1) p {
    font-weight: 600;
    font-size: 18px;
}

.cardSelect .flx:nth-child(2) p {
    font-weight: 400;
    color: #333;
    font-size: 16px;
}

.cardSelect .flx {
    display: flex;
    justify-content: space-between;
}
@media (max-width: 767px) {
    .ptp-wrapper .od-head {
        margin-top: 10px;
    }
}
@media (max-width: 670px) {
    .checkout .media-left {
        margin: auto;
        margin-right: auto;
        border-radius: 10px;
        margin-bottom: 10px;
        max-width: unset;
    }
}
