.filterPanel .fpTitle {
    justify-content: flex-end;
}

.filterPanel .fpTitle h4 {
    font-size: 13px;
    color: #b2b2b2;
    font-weight: 400;
    padding-right: 20px;
    text-align: left;
    margin-bottom: 5px;
}

.filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root {
    border: 1px solid var(--primColor);
    min-height: 40px;
    height: 45px;
    border-radius: 5px;
    margin: 5px 0px;
    text-align: right;
    background: var(--primColor);
    text-transform: uppercase;
}
.filterPanel .filterAcc .Mui-expanded .MuiButtonBase-root.MuiAccordionSummary-root {
    border-radius: 5px !important;
}
.filterPanel .MuiCollapse-wrapperInner small {
    width: 100%;
    text-align: left;
}

.filterPanel .MuiPaper-root {
    background-color: transparent;
}

.filterPanel .MuiPaper-root.MuiAccordion-root {
    border: none;
    border-radius: 5px;
}
.filterAcc {
    /* border: 1px solid var(--primColor); */
    border-radius: 5px;
}
.filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root
    .accTitle {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}

.filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
    .accTitle {
    color: #fff;
}
.filterPanel
    .filterAcc
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
    .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon {
    color: #fff;
}

.filterPanel .MuiAccordionSummary-content {
    justify-content: flex-start;
}

.filterTitleCnt {
    margin-bottom: 15px;
}

.filterPanel h4 {
    margin-bottom: 0;
}

.filterPanel .filterClear {
    text-transform: none;
    color: #8e8e8e;
    font-size: 13px;
}

.filterPanel .filterClear .material-icons {
    padding-right: 5px;
    font-size: 20px;
}

.filterPanel .MuiAccordion-root.Mui-expanded:last-child .MuiAccordionDetails-root {
    border-bottom: 1px solid #cccccc;
}

.filterPanel .MuiAccordionDetails-root {
    padding: 10px 16px;
    /* justify-content: flex-end !important; */
    border: 1px solid #cccccc;
    background: transparent;
    /* border-bottom: none; */
}

.filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row {
    width: 100%;
}

.filterPanel .filterCheck .MuiFormControlLabel-root,
.filterPanel .filterCheck .MuiTypography-root.MuiFormControlLabel-label,
.filterPanel .filterCheck .MuiListItem-button {
    width: 100%;
    margin: 0;
}
/* 
.filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row .MuiCheckbox-root {
    display: none;
} */

.filterPanel
    .filterCheck
    .MuiFormGroup-root.MuiFormGroup-row
    .MuiCheckbox-root.Mui-checked
    ~ span
    .MuiListItem-root {
    color: #fff;
    font-weight: 700;
    /* background-color: #ffff00; */
}

.filterCheck .filterCB {
    float: right;
    width: 100%;
}

.filterPanel .filterCheck .MuiListItem-button {
    padding: 5px;
    font-size: 14px;
    color: #fff !important;
    justify-content: flex-start;
}

.RadioBox .MuiListItem-button {
    padding: 0;
}

.RadioBox .MuiAccordionDetails-root {
    padding-top: 0;
}
.filterCheck .filterCB svg {
    color: #fff;
}
.filterPanelSkeleton {
    /* height: 60vh; */
    margin-bottom: 30px;
}

@media (max-width: 1024px) {
    .filterPanel .MuiCollapse-wrapperInner small {
        width: 100%;
        text-align: left;
    }
}

.filterClearNew .MuiButton-label {
    font-size: 15px !important;
    color: var(--primColor);
}

.filterCntNew {
    margin-top: 5px;
    justify-content: end;
}

.filterTagsNew {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500;
    color: #fff;

    text-transform: capitalize;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.filterTagsNew .material-icons {
    font-size: 18px;
    color: var(--primColor);
    margin-left: 5px;
    cursor: pointer;
}

.accTitleNew {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    text-align: right;
    margin-right: 8px;
    color: #fff;
}
