.loader {
    background: #fff;
    padding: 10px;
    margin: 10px 0px;
    /* min-height: 60vh; */
}
.loader h3.loaderText {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--primColor);
    margin: 0;
    margin-left: 15px;
}
