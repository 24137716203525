.home .homeBanner {
    background: url('../../assets/images/homeBg.png');
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-size: cover;
    padding: 60px;
    position: relative;
    margin-bottom: 150px;
    background-position: bottom;
}

.home .actButtons {
    margin-top: 30px;
}

.home .actButtons .primButton {
    min-width: 250px;
}

.home .actButtons .primButton button {
    border-radius: 50px !important;
    border-bottom: none;
}

.home .actButtons .primButton:not(:last-child) {
    margin-right: 45px;
}

.home .actButtons .primButton img {
    width: 30px;
    margin-left: 20px;
}

.home .homeInfo .dividerLine {
    padding: 30px 0;
    max-width: 100%;
    /* width: 100%; */
}

.home .homeInfo h4 {
    font-size: 22px;
    color: #fff;
    font-weight: 500;
}

.home .productPreviewImages {
    position: relative;
    transform: translate(-10%, 50%);
    margin: 0 auto;
    height: 500px;
    margin-bottom: 60px;
}

.home .productPreviewImages img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid #fff;
    border-radius: 10px;
    box-shadow: 0px 5px 10px #616161;
}

.home .productPreviewImages img:first-child {
    z-index: 1;
    width: 700px;
    object-fit: cover;
    height: 450px;
    object-position: top;
    box-shadow: 0px 0px 14px #949494c4;
}

.home .productPreviewImages img:last-child {
    top: -140px;
    left: 80%;
    width: 500px;
    object-fit: contain;
    height: 69%;
    object-position: top;
    background: #fff;
}

@media (max-width: 991px) {
    .home .actButtons .primButton:not(:last-child) {
        margin-right: 45px;
    }
    .home .actButtons .primButton {
        min-width: 220px;
    }
    .home .productPreviewImages img:first-child {
        width: 600px;
    }
    .home .productPreviewImages {
        margin-bottom: 0;
    }
    .home .productPreviewImages {
        transform: translate(-10%, 40%);
        height: 600px;
    }
}

@media (max-width: 800px) {
    .home .productPreviewImages img:last-child {
        left: 70%;
    }
    .home .homeBanner {
        margin-bottom: 100px;
    }
    .home .actButtons {
        flex-wrap: wrap;
    }
    .home .actButtons .primButton:not(:last-child) {
        margin-right: 20px;
    }
    .home .actButtons .primButton {
        margin-top: 20px;
    }
}

@media (max-width: 767px) {
    .home .productPreviewImages img:first-child {
        max-width: 500px;
        width: 100%;
        height: 350px;
    }
    .home .productPreviewImages img:last-child {
        top: -100px;
        max-width: 400px;
        width: 100%;
    }
    .home .productPreviewImages {
        transform: translate(-10%, 30%);
        height: 500px;
    }
    .home .homeBanner {
        margin-bottom: 20px;
    }
    footer .footCopyright {
        text-align: center;
    }
    footer .footCopyright p,
    footer .footCopyright a {
        width: 100%;
        color: #fff;
    }
    footer .footCopyright ul {
        margin: 10px auto;
    }
}

@media (max-width: 620px) {
    .home .productPreviewImages {
        transform: translate(-5%, 30%);
    }
}

@media (max-width: 580px) {
    .home .actButtons {
        margin-top: 0;
    }
    .home .actButtons .primButton:not(:last-child) {
        margin-right: 0px;
    }
    .home .actButtons .primButton {
        width: 280px;
    }
    .home .actButtons .primButton button .MuiButton-label {
        padding: 0 15px;
    }
    .home .actButtons .primButton img {
        margin-left: auto;
    }
    .home .homeInfo h4 {
        font-size: 16px;
    }
    .home .homeInfo .dividerLine {
        padding: 20px 0;
    }
}

@media (max-width: 545px) {
    .home .productPreviewImages img:first-child {
        height: auto;
        max-height: 400px;
    }

    .home .productPreviewImages img:last-child {
        left: 60%;
    }
    .home .productPreviewImages {
        transform: translate(-5%, 40%);
        height: 600px;
    }
    .home .homeBanner {
        padding: 30px;
        background-position: center;
    }
    footer .footCnt .footLt {
        flex-wrap: wrap;
    }
    footer .footCnt .footLt .footLogo {
        width: 100%;
    }
    footer .footCnt .footLt .footLogo img {
        margin: 0 auto;
        margin-bottom: 20px;
    }
    footer .footCnt .footLt .footLinks {
        margin: 0 auto;
    }
    footer .footCopyright ul li a {
        font-size: 13px;
    }
    footer .footCopyright ul li:not(:last-child) {
        margin-right: 15px;
    }
}

@media (max-width: 480px) {
    .home .productPreviewImages {
        height: 400px;
    }
    .home .productPreviewImages img:first-child {
        height: auto;
        max-height: 320px;
    }
}

@media (max-width: 345px) {
    .home .productPreviewImages img:first-child {
        height: auto;
        max-height: 200px;
    }
}
