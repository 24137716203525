.filterPanel {
    position: sticky;
    top: 15px;
    margin-top: 30px;
}
.pswp-gallery .pswp-thumbnails {
    display: none !important;
}

/* GRID */
.productCardGrid {
    width: 100%;
    text-align: left;
    background: #fff;
    padding: 10px;
    height: auto;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 10%);
    border-radius: 5px;
}

.gridBidInfo .curr {
    margin-bottom: 0px !important;
}
.productCardGrid .gridBidInfo.starting {
    margin-top: 0px;
}
.productCardGrid .pcgImg {
    width: 100%;
    height: 250px;
    position: relative;
}

.productCardGrid .pcgImg .TimeSlot {
    position: absolute;
    background-color: #fff;
    opacity: 0.7;
    top: 76%;
    padding: 10px;
    width: 100%;
}

.productCardGrid .pcgImg .aucEnded {
    top: 0%;
    left: 0;
    /* transform-origin: left; */
    white-space: nowrap;
    /* transform: translate(-35%, 140%) rotate(-45deg); */
    width: 80%;
    width: max-content;
    padding: 5px 20px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    height: 30px;
    background: #757575 !important;
    border-radius: 3px;
    position: absolute;
    color: #fff;
}

.productCardList .pclImg .aucEnded {
    top: 50%;
    left: 50%;
    transform-origin: left;
    transform: translate(-45%, 55%) rotate(-25deg);
    width: 90%;
    width: max-content;
    padding: 5px 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    height: 30px;
    background: #757575 !important;
    border-radius: 3px;
    position: absolute;
    color: #fff;
}

.productCardGrid .pcgImg .favoriteCheck {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
}

.productCardGrid .primButton.small button,
.productCardGrid .secButton.small button {
    font-size: 13px;
    min-width: max-content;
    width: 100%;
}

.productCardGrid .productWinningStatus,
.productCardList .productWinningStatus {
    padding: 10px 30px;
    color: #fff;
    height: 30px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    margin: 0;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: 5px;
    left: 5px;
    width: max-content;
    line-height: 1;
}

.productCardGrid .productWinningStatus.winning,
.productCardList .productWinningStatus.winning {
    background: #06a2b4;
}

.productCardGrid .productWinningStatus.won,
.productCardList .productWinningStatus.won {
    background: #06b473;
}

.productCardGrid .productWinningStatus.outbid,
.productCardList .productWinningStatus.outbid {
    background: #ff9472;
}

.productCardGrid .productWinningStatus.lost,
.productCardList .productWinningStatus.lost {
    background: #ff7272;
}

.productCardList .listActBtn.ended a {
    text-decoration: none;
}

.productCardList .listActBtn.ended .primButton button {
    background: var(--primColor);
    border-color: var(--primColor);
    color: #000;
}

.productCardGrid .pcgImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.productCardGrid .gridProdTitle {
    font-size: 22px;
    /* color: var(--primColor); */
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 4.5ex;
    word-break: break-word;
}
.productCardGrid .gridProdTitle a {
    color: var(--secColor) !important;
    font-weight: 600 !important;
    font-size: 18px;
}
.productCardGrid .gridLotInfo .gridDivider {
    padding: 0 10px;
}

.productCardGrid .gridLotDetails,
.productCardList .gridLotDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    height: 24px;
}

.productCardGrid .gridLotDetails.fr1,
.productCardList .gridLotDetails.fr1 {
    grid-template-columns: 1fr;
}

.productCardGrid .gridLotDetails p,
.productCardList .gridLotDetails p {
    font-size: 13px;
    color: var(--primColor);
    font-weight: 600;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.productCardGrid .gridLotDetails p label,
.productCardList .gridLotDetails p label {
    font-size: 13px;
    color: #767676;
    font-weight: 500;
    padding: 0px 5px;
}

.productCardGrid .priceTimeCnt {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
    height: 40px;
    /* margin: 20px 0px; */
}

.productCardGrid .gridTimer .material-icons {
    padding-right: 10px;
}

.productCardGrid .gridTimer {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    background: rgba(55, 55, 55, 70%);
    padding: 8px 15px;
    color: #fff;
}

.productCardGrid .gridTimer h6 {
    margin: 0;
    font-size: 12px;
    padding-right: 5px;
}

.productCardGrid .gridTimer p {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
}

.productCardGrid .gridBidInfo {
    margin-top: 5px;
    flex-wrap: wrap;
}

.productCardGrid .gridBidInfo .hasIt {
    margin-bottom: 4px;
    font-size: 14px;
    color: #e10000;
    margin-bottom: 0px !important;
    margin-right: 10px;
    /* width: 100%; */
}
.table.laTable .thead-dark th {
    font-weight: 500;
    white-space: nowrap;
}
.description .MuiAccordion-root {
    margin: 15px 0px;
    box-shadow: none !important;
    border-radius: 5px;
}
.viewFullDetails {
    text-decoration: underline;
    font-size: 20px;
}

.closeMe {
    color: var(--secColor) !important;
}
.description .MuiAccordionSummary-root {
    background-color: #e6e6e6;
    border-radius: 5px;
    box-shadow: none !important;
    font-weight: 500;
    color: var(--secColor);
    max-height: 48px;
    min-height: unset !important;
}
.description .MuiAccordionDetails-root {
    display: block !important;
    color: var(--secColor);
}
.prodInfoSlider {
    padding-top: 20px;
    color: var(--secColor);
}
.prodInfoSlider .image-gallery {
    /* box-shadow: 0 0 6px 0 rgba(0, 0, 0, 20%); */
    margin-top: 20px;
}
.prodInfoSlider .price {
    font-size: 30px;
    color: var(--secColor);
    font-weight: bold;
}
.productCardGrid .prodEndDate {
    font-size: 14px;
    color: #3f3c53;
    margin-bottom: 0px;
}

.productCardGrid .gridBidInfo p {
    margin-bottom: 0;
    color: #e10000;
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
}

.productCardGrid .gridBidInfo p span.gridDivider {
    padding: 0 15px;
}

.productCardGrid .gridTimer p span {
    padding-right: 5px;
}

.productCardGrid .gridBidBox {
    margin-top: 15px;
}

.productCardGrid .gridBidBox.ended a {
    text-decoration: none;
}

.productCardGrid .gridBidBox.ended .primButton button {
    background: var(--primColor);
    border-color: var(--primColor);
    color: #fff;
}

.productCardGrid .gridBidBox .MuiFormhelperText-root.Mui-error {
    width: 100%;
    margin-left: 0;
}

.productCardGrid .gridBidBox .customInput {
    margin: 0;
    margin-right: 10px;
    width: 49%;
}

.productCardGrid .gridBidBox .customInput input::placeholder {
    font-size: 12px;
}

.lineItemBidAct .customInput {
    width: fit-content;
}
.lineItemBidAct .primButton {
    margin-top: 5px;
}
.lineItemBidAct .customInput .MuiInputBase-input {
    width: 100%;
    min-width: 116px;
}
.productCardGrid .gridBidBox .primButton {
    /* width: 49%; */
    width: 100%;
}

/* GRID END */

/* SPECIAL EVENT */

.specialEvent {
    background: #fff;
    padding: 10px 15px;
    margin-bottom: 10px;
}

.specialEvent .specialEventLt {
    width: 70%;
}

.specialEvent .seImg {
    width: 100%;
    max-width: 250px;
    position: relative;
    margin-right: 20px;
}

.specialEvent .seImg img {
    width: 100%;
    height: 130px;
    object-fit: contain;
}

.specialEvent .seContent {
    margin-right: 15px;
    text-align: left;
}

.specialEvent .seContent h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: var(--primColor);
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.specialEvent .seContent p {
    font-size: 14px;
    font-weight: 400;
    color: #595959;
    margin-bottom: 5px;
}

.specialEventDrawer .calItems {
    padding-bottom: 20px;
}

.specialEventDrawer .calItems h3 {
    font-size: 14px;
    color: var(--secColor);
    font-weight: 700;
    padding: 25px 16px 5px;
    text-align: left;
    text-transform: uppercase;
}

.specialEventDrawer .calItems .ciIcon {
    padding-right: 15px;
}

.specialEventDrawer .MuiPaper-root.MuiDrawer-paper {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

p.seAddress {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.specialEvent .seActBtn {
    width: 30%;
    max-width: 250px;
}

.specialEvent .seActBtn .atcBtn {
    padding-left: 0;
}

.specialEvent .seActBtn .seLinks button.MuiButton-root,
.specialEvent .MuiFab-primary {
    color: var(--primColor);
    text-transform: initial;
    font-size: 14px;
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.specialEvent .seActBtn .seLinks button.MuiButton-root:first-child {
    margin-top: 5px;
    padding-left: 0;
}

.specialEvent .seActBtn .seLinks button.MuiButton-root i,
.specialEvent .MuiFab-primary i {
    padding-right: 10px;
}

.specialEvent .MuiFab-primary {
    background-color: #fff;
    box-shadow: none;
    width: 100%;
    border-radius: 3px;
    height: auto;
    /* flex-shrink: 0; */
    min-width: max-content;
}

.specialEvent .MuiFab-primary:hover {
    background-color: #fff;
    box-shadow: none;
}

.specialEvent .MuiSpeedDial-directionLeft {
    flex-direction: row;
    padding: 6px 8px;
    height: 36px;
    position: relative;
}

.specialEvent .MuiSpeedDial-actions {
    position: absolute;
    /* padding: 6px 8px; */
    /* left: 50px;
    transform: translateX(-100%); */
}

/* SPECIAL EVENT END */

/* LIST */

.productCardList {
    background: #fff;
    padding: 15px;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 10%);
    border-radius: 5px;
}

.productCardList .psListLt {
    width: 85%;
    border-right: 1px solid #e4e4e4;
    margin-right: 10px;
    position: relative;
}
/* .timeListBidInfo {
    position: absolute;
    bottom: 0;
    right: 15px;
    justify-content: end;
} */
.listBidPrice {
    margin: 0;
    font-size: 14px;
    color: #e10000;
    font-weight: 600;
    text-align: left;
}
.listBidPrice p {
    margin-bottom: 6px;
    margin-left: 5px;
}
.listBidPrice h6 {
    margin-left: 4px;
}
.productCardList .listLotDetails {
    margin-bottom: 30px;
    margin-top: 25px;
}

.productCardList .pclImg {
    width: 100%;
    max-width: 130px;
    position: relative;
    margin-right: 20px;
}

.productCardList .pclImg img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 3px;
}

.productCardList .pclImg .favoriteCheck {
    position: absolute;
    top: 0;
    right: 0;
}

.productCardList .listContent {
    width: max-content;
    text-align: left;
    margin-right: 20px;
    /* display: grid; */
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
}

.productCardList .listActBtn {
    width: 15%;
}

.productCardList .listActBtn .primButton {
    /* margin-bottom: 15px; */
}

.productCardList .listContent .listProdTitle a {
    font-size: 18px;
    color: var(--secColor) !important;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.productCardList .listActBox {
    width: 30%;
}

.productCardList > p {
    font-size: 14px;
    color: #646464;
    font-weight: 600;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.productCardList .listBidInfo h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    padding-right: 25px;
}

.productCardList .listTimer h6 {
    width: 100%;
    font-size: 14px;
    color: #373737;
    margin-bottom: 5px;
}

.productCardList .listTimer p {
    margin: 0;
    font-size: 15px;
    color: var(--primColor);
    font-weight: 600;
}

.productCardList .listBidInfo p {
    margin: 0;
    font-size: 14px;
    color: #e10000;
    font-weight: 600;
    text-align: right;
}

.productCardList .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.productCardList .listBidInfo {
    margin-top: 0px;
    margin-left: 5px;
    width: 100%;
}

.productCardList .listTimer p span {
    padding-right: 5px;
}

.productCardList .listBidBox {
    margin: 15px 0;
}

.productCardList .listBidBox .biddingCnt {
    flex-wrap: wrap;
}

.productCardList .listBidBox .customInput {
    width: 100%;
    margin-bottom: 10px;
}

.productCardList .listBidBox .primButton,
.productCardList .listBidBox .secButton {
    width: 100%;
}

.productCardList button.bidDetails {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

/* LIST END */

/* CART ITEM */

.cartItem {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
}

.cartItem .itemInfo {
    width: 75%;
}

.cartItem .pclImg {
    width: 100%;
    max-width: 200px;
    position: relative;
    margin-right: 20px;
}

.cartItem .pclImg img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 3px;
}

.cartItem .listContent {
    width: max-content;
    text-align: left;
    margin-right: 20px;
}

.cartItem .listActBtn {
    width: 25%;
    max-width: max-content;
}

.cartItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.cartItem .listContent .listLotInfo {
    margin: 5px 0;
}

.cartItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
    line-height: 25px;
}

.cartItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

.cartItem .listActBox {
    width: 30%;
}

.cartItem .listBidInfo p {
    font-size: 16px;
    color: var(--secColor);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.cartItem .listBidInfo h6 {
    margin: 0;
    font-size: 14px;
    color: var(--secColor);
    padding-right: 10px;
}

.cartItem .listBidInfo p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.cartItem .listBidInfo p span:first-child {
    color: var(--primColor);
}

.cartItem .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.cartItem .listBidInfo {
    margin-top: 5px;
}

.cartItem .listTimer p span {
    padding-right: 5px;
}

.cartItem .listBidBox .customInput {
    margin: 15px 0;
}

.cartItem button.removeCart {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

/* CART ITEM END */

/* SIMILAR ITEMS */
.similarItem {
    background: #fff;
    padding: 10px;
    /* margin-bottom: 10px; */
}

.similarItem .pclImg {
    width: 25%;
    margin-right: 30px;
}

.similarItem .pclImg img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 3px;
}

.similarItem .listContent {
    text-align: left;
    width: 70%;
}

.similarItem .listContent .listProdTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.similarItem .listContent .listLotInfo {
    margin: 5px 0;
}

.similarItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
    line-height: 25px;
}

.similarItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

/* SIMILAR ITEMS END */

.actionAfterWin {
    margin: 15px 0 5px 0;
}

.actionAfterWin .primButton,
.actionAfterWin .secButton {
    width: 100% !important;
}

/* Skeletons */

.gridViewSkeleton {
    width: 100%;
    height: 460px;
    max-width: 350px;
}
.lotDataSort .MuiSelect-outlined.MuiSelect-outlined {
    min-width: 60px;
}
.listViewSkeleton {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    margin: 15px 0;
    background: #f7f7f7;
}

/* .bidHistoryModal .custom-modal .modal-dialog.modal-lg {
    max-width: 500px;
} */

/* .bidHistoryModal table thead tr th:first-child,
.bidHistoryModal table tbody tr td:first-child {
    width: 55%;
} */

.bidHistoryModal table thead th,
.bidHistoryModal table tbody td {
    text-align: left;
    white-space: nowrap;
}

.customModal .bidHistoryModal .modal-dialog.modal-lg {
    max-width: 550px;
}

.productCardGrid .moreInfo button,
.productCardList .moreInfo button {
    color: var(--secColor);
    text-transform: initial;
}

.productCardGrid .moreInfo button .material-icons,
.productCardList .moreInfo button .material-icons {
    padding-left: 5px;
}
@media (max-width: 1270px) {
    .timeListBidInfo {
        top: unset;
        bottom: -10px;
    }
}
@media (max-width: 1100px) {
    .productCardList .listContent {
        width: -webkit-fill-available;
        grid-template-columns: auto;
    }

    .productCardList .listLotDetails {
        margin-bottom: 5px;
    }

    /* .productCardList .listBidInfo {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        gap: 15px;
    } */

    /* .productCardList .listBidInfo p {
        text-align: left;
    } */
}

@media (max-width: 768px) {
    .cartItem {
        flex-wrap: wrap;
        width: 48%;
    }

    .cartItem .itemInfo {
        width: 100%;
        flex-wrap: wrap;
    }

    .cartItem .pclImg {
        max-width: none;
        margin-right: 0;
    }

    .cartItem .pclImg img {
        height: 200px;
    }

    .cartItem .listContent {
        width: 100%;
        margin-right: 0;
    }
    .pvTitle,
    .prodInfoSlider .price {
        font-size: 20px;
    }
    .cartItem .listActBtn {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .lineItemBidAct .customInput {
        width: 100%;
    }

    .productCardGrid .pcgImg .aucEnded {
        transform: translate(0%, 0%) rotate(0deg);
    }

    .specialEvent .seContent h2 {
        font-size: 16px;
        line-height: 20px;
    }

    .specialEvent .seContent p {
        font-size: 13px;
        margin-bottom: 2px;
    }

    .lineItemView .lineItemAction .customInput {
        margin-right: 0 !important;
    }

    .biddingCnt .lineItemBidAct .primButton {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .cartItem .pclImg img {
        height: 150px;
    }

    .cartItem .itemInfo {
        width: 100%;
    }

    .productCardGrid .gridProdTitle {
        font-size: 16px;
    }

    .productCardGrid .gridTimer h6,
    .productCardGrid .gridBidInfo h6 {
        font-size: 13px;
    }

    .search .searchResults .productCardGrid,
    .dashboard .productCardGrid {
        width: 100%;
    }

    .productCardGrid .gridBidBox .customInput,
    .productCardGrid .gridBidBox .customInput .MuiFormControl-root.MuiTextField-root {
        height: 45px;
    }

    .productCardGrid .gridBidBox .customInput input {
        height: 100%;
    }

    .productCardGrid .gridBidBox .primButton,
    .productCardGrid .gridBidBox .secButton {
        /* width: 49%; */
    }

    .similarItem .listContent .listProdTitle {
        font-size: 15px;
    }

    .similarItem .pclImg {
        width: 30%;
        margin-right: 10px;
    }

    .cartItem {
        width: 100%;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem button.removeCart {
        width: max-content;
    }
}

@media (max-width: 450px) {
    .specialEvent .seContent h2 {
        font-size: 14px;
    }

    .specialEvent .seContent p {
        font-size: 12px;
    }

    .specialEvent .seContent {
        margin-bottom: 10px;
    }
}

/* @media (min-width: 1440px) {
  .productCardGrid {
    width: 24%;
  }
} */
