.App {
    text-align: center;
    /* background-color: var(--secColor); */
    min-height: calc(100vh - 210px);
}
.container {
    max-width: 1440px;
    padding-left: 0;
    padding-right: 0;
}
.primeColor {
    color: var(--primColor) !important;
}
.headRt .MuiListItem-gutters {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
header .headLt ul li:last-child {
    margin-left: 0px !important;
}
/* @media screen and (max-width: 600px) {
    .MuiButton-outlinedSizeLarge {
        padding: 2px 5px !;
        margin-top: 82px !important;
    }
} */
